// ** MUI Imports
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import { Link as MUILink, useScrollTrigger, useTheme } from '@mui/material'
import Link from 'next/link'

// ** Icons Imports
import MenuIcon from 'mdi-material-ui/Menu'

// ** Type Import
import { Settings } from 'src/@core/context/settingsContext'
import router from 'next/router'

interface Props {
  hidden: boolean
  settings: Settings
  toggleNavVisibility: () => void
  saveSettings: (values: Settings) => void
}

const AppBarContent = (props: Props) => {
  const theme = useTheme()

  // ** Props
  const { hidden, toggleNavVisibility } = props

  // ** Scroll Trigger Hook
  const scrollTrigger = useScrollTrigger({ threshold: 0, disableHysteresis: true })

  // Determine the logo based on the scroll position
  const logoSrc = scrollTrigger
    ? theme.palette.mode === 'dark'
      ? '/images/logo-dark.png'
      : '/images/logo-light.png'
    : '/images/logo-not-scroll.svg'

  // Determine the logo based on the scroll position
  const logoSrcStatic = theme.palette.mode === 'dark' ? '/images/logo-dark.png' : '/images/logo-light.png'

  // Var for define routes with transparent navbar
  const routesWithTransparentNavbar = ['/home-page', '/dashboards', '/dashboards/', '/profile']
  const showTransparentNavbar = routesWithTransparentNavbar.some(route => router.pathname.startsWith(route))

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
      }}
    >
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        {hidden ? (
          <>
            <Box className='actions-left' sx={{ mr: 2, display: 'flex', alignItems: 'center' }}>
              <Link passHref href='/'>
                <MUILink>
                  <img
                    src={showTransparentNavbar ? logoSrc : logoSrcStatic}
                    alt='Dashbi'
                    style={{ width: '118px', height: '30px' }}
                  />
                </MUILink>
              </Link>
            </Box>
            <Box className='actions-right' sx={{ display: 'flex', alignItems: 'center' }}>
              <IconButton sx={{ padding: '7.5px' }} onClick={toggleNavVisibility}>
                <MenuIcon
                  sx={{
                    height: 35,
                    width: 35,
                    color: !showTransparentNavbar
                      ? theme.palette.mode === 'dark'
                        ? 'common.white'
                        : 'common.black'
                      : scrollTrigger
                      ? theme.palette.mode === 'dark'
                        ? 'common.white'
                        : 'common.black'
                      : 'common.white'
                  }}
                />
              </IconButton>
            </Box>
          </>
        ) : null}
      </Box>
    </Box>
  )
}

export default AppBarContent
