// ** React Imports
import { ReactNode } from 'react'

// ** MUI Imports
import { styled, useTheme } from '@mui/material/styles'
import useScrollTrigger from '@mui/material/useScrollTrigger'
import MuiAppBar, { AppBarProps } from '@mui/material/AppBar'
import MuiToolbar, { ToolbarProps } from '@mui/material/Toolbar'

// ** Type Import
import { Settings } from 'src/@core/context/settingsContext'
import router from 'next/router'

interface Props {
  hidden: boolean
  settings: Settings
  toggleNavVisibility: () => void
  saveSettings: (values: Settings) => void
  verticalAppBarContent?: (props?: any) => ReactNode
}

const AppBar = styled(MuiAppBar)<AppBarProps>(({ theme }) => ({
  transition: 'none',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(0, 6),
  backgroundColor: 'transparent',
  color: theme.palette.text.primary,
  minHeight: theme.mixins.toolbar.minHeight,
  [theme.breakpoints.down('sm')]: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4)
  }
}))

const Toolbar = styled(MuiToolbar)<ToolbarProps>(() => ({
  width: '100%',
  padding: '0 !important',
  transition: 'padding .25s ease-in-out, box-shadow .25s ease-in-out, backdrop-filter .25s ease-in-out'
}))

const LayoutAppBar = (props: Props) => {
  // ** Props
  const { settings, verticalAppBarContent: userVerticalAppBarContent } = props

  // ** Hooks
  const theme = useTheme()
  const scrollTrigger = useScrollTrigger({ threshold: 0, disableHysteresis: true })

  // ** Vars
  const { appBar } = settings

  const appBarFixedStyles = () => {
    return {
      backgroundColor: theme.palette.background.paper,
      transition: 'background-color 0.25s ease-in-out'
    }
  }

  if (appBar === 'hidden') {
    return null
  }

  // Var for define routes with transparent navbar
  const routesWithTransparentNavbar = ['/home-page', '/dashboards', '/dashboards/', '/profile']
  const showTransparentNavbar = routesWithTransparentNavbar.some(route => router.pathname.startsWith(route))

  return (
    <AppBar
      data-html2canvas-ignore='true'
      elevation={0}
      color='default'
      className='layout-navbar'
      position={appBar === 'fixed' ? 'sticky' : 'static'}
      sx={{
        ...(!scrollTrigger && {
          [theme.breakpoints.down('md')]: {
            backgroundColor: showTransparentNavbar ? 'transparent' : 'background.paper',
            [theme.breakpoints.down('sm')]: {
              paddingLeft: `${theme.spacing(4.3)} !important`
            },
            transition: 'background-color 0.25s ease-in-out'
          }
        }),

        ...(appBar === 'fixed' &&
          scrollTrigger && {
            ...appBarFixedStyles(),
            [theme.breakpoints.down('md')]: {
              ...appBarFixedStyles()
            }
          })
      }}
    >
      <Toolbar className='navbar-content-container'>
        {(userVerticalAppBarContent && userVerticalAppBarContent(props)) || null}
      </Toolbar>
    </AppBar>
  )
}

export default LayoutAppBar
