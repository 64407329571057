// ** MUI Imports
import { Box, PaletteMode, Switch, Typography } from '@mui/material'

// ** Type Import
import { Settings } from 'src/@core/context/settingsContext'

interface Props {
  settings: Settings
  saveSettings: (values: Settings) => void
}

const ModeToggler = (props: Props) => {
  // ** Props
  const { settings, saveSettings } = props

  const handleModeChange = (mode: PaletteMode) => {
    saveSettings({ ...settings, mode })
  }

  const handleModeToggle = () => {
    if (settings.mode === 'light') {
      handleModeChange('dark')
    } else {
      handleModeChange('light')
    }
  }

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Switch checked={settings.mode === 'dark'} onChange={handleModeToggle} />
      <Typography>Dark Mode</Typography>
    </Box>
  )
}

export default ModeToggler
