import { Divider, MenuItem, Menu, Box } from '@mui/material'
import { AccountOutline, LogoutVariant } from 'mdi-material-ui'
import { useAuth } from 'src/hooks/useAuth'

// Props of UserMenu
interface UserMenuProps {
  anchorEl: Element | null
  handleDropdownClose: (url?: string) => void
  direction: string
}

const UserMenu = (props: UserMenuProps) => {
  const { logout } = useAuth()

  const styles = {
    py: 2,
    px: 4,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    color: 'text.primary',
    backgroundColor: 'background.paper',
    textDecoration: 'none',
    '&:hover': {
      backgroundColor: 'action.hover'
    },
    '& svg': {
      fontSize: '1.375rem',
      color: 'text.secondary'
    }
  }

  const handleLogout = () => {
    logout()
  }

  return (
    <>
      <Menu
        anchorEl={props.anchorEl}
        keepMounted
        open={Boolean(props.anchorEl)}
        onClose={() => props.handleDropdownClose()}
        sx={{ '& .MuiMenu-paper': { width: 230, marginTop: 4 } }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: props.direction === 'ltr' ? 'right' : 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: props.direction === 'ltr' ? 'right' : 'left'
        }}
      >
        <MenuItem sx={{ p: 0 }} onClick={() => props.handleDropdownClose('/profile')}>
          <Box sx={styles}>
            <AccountOutline sx={{ marginRight: 2 }} />
            Profile
          </Box>
        </MenuItem>
        <Divider />
        <MenuItem sx={{ py: 2 }} onClick={handleLogout}>
          <LogoutVariant
            sx={{
              marginRight: 2,
              fontSize: '1.375rem',
              color: 'text.secondary'
            }}
          />
          Logout
        </MenuItem>
      </Menu>
    </>
  )
}

export default UserMenu
