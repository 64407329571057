// ** React Imports
import { useState } from 'react'

// ** MUI Imports
import Fab from '@mui/material/Fab'
import { styled } from '@mui/material/styles'
import Box, { BoxProps } from '@mui/material/Box'
import { useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'

// ** Icons Imports
import ArrowUp from 'mdi-material-ui/ArrowUp'

// ** Theme Config Import
import themeConfig from 'src/configs/themeConfig'

// ** Type Import
import { LayoutProps } from 'src/@core/layouts/types'

// ** Components
import AppBar from './components/vertical/appBar'
import Customizer from 'src/@core/components/customizer'
import Navigation from './components/vertical/navigation'
import Footer from './components/shared-components/footer'
import ScrollToTop from 'src/@core/components/scroll-to-top'

// ** Styled Component
import DatePickerWrapper from 'src/@core/styles/libs/react-datepicker'
import { useRouter } from 'next/router'

const VerticalLayoutWrapper = styled('div')({
  height: '100%',
  display: 'flex'
})

const MainContentWrapper = styled(Box)<BoxProps>({
  flexGrow: 1,
  minWidth: 0,
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column'
})

const ContentWrapper = styled('main')(({ theme }) => ({
  flexGrow: 1,
  width: '100%',
  padding: theme.spacing(6),
  transition: 'padding .25s ease-in-out',
  [theme.breakpoints.up('md')]: {
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4)
  },
  [theme.breakpoints.down('sm')]: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4)
  }
}))

const FullContentWrapper = styled('main')(() => ({
  flexGrow: 1,
  transition: 'padding .25s ease-in-out'
}))

const BackgroundBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  position: 'absolute',
  left: '0px',
  top: '0px',
  zIndex: -1,
  height: '590px',
  width: '100%',
  padding: '0',
  borderRadius: '0px 0px 12px 12px',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  [theme.breakpoints.between('xs', 'sm')]: {
    backgroundImage:
      theme.palette.mode === 'light'
        ? 'url(/images/pages/background_light.png)'
        : 'url(/images/pages/background_dark.png)'
  },
  [theme.breakpoints.between('sm', 'md')]: {
    backgroundImage:
      theme.palette.mode === 'light'
        ? 'url(/images/pages/background_light.png)'
        : 'url(/images/pages/background_dark.png)'
  }
}))

const VerticalLayout = (props: LayoutProps) => {
  // ** Props
  const { hidden, settings, children, scrollToTop } = props

  // ** Vars
  const { skin, navHidden, contentWidth } = settings
  const { navigationSize, disableCustomizer, collapsedNavigationSize } = themeConfig
  const navWidth = navigationSize
  const navigationBorderWidth = skin === 'bordered' ? 1 : 0
  const collapsedNavWidth = collapsedNavigationSize
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  // ** States
  const [navHover, setNavHover] = useState<boolean>(false)
  const [navVisible, setNavVisible] = useState<boolean>(false)

  // ** Toggle Functions
  const toggleNavVisibility = () => setNavVisible(!navVisible)

  // ** Router
  const router = useRouter()
  const routesWithBackground = ['/home-page', '/dashboards', '/dashboards/', '/profile']
  const routesWithFullWrapper = ['/home-page']
  const showBackground = routesWithBackground.some(route => router.pathname.startsWith(route))
  const useFullWrapper = routesWithFullWrapper.some(route => router.pathname.startsWith(route))

  return (
    <>
      <VerticalLayoutWrapper className='layout-wrapper'>
        {showBackground && <BackgroundBox />}
        {/* Navigation Menu */}
        {navHidden &&
        themeConfig.layout === 'vertical' &&
        !(navHidden && settings.lastLayout === 'horizontal') ? null : (
          <Navigation
            navWidth={navWidth}
            navHover={navHover}
            navVisible={navVisible}
            setNavHover={setNavHover}
            setNavVisible={setNavVisible}
            collapsedNavWidth={collapsedNavWidth}
            toggleNavVisibility={toggleNavVisibility}
            navigationBorderWidth={navigationBorderWidth}
            {...props}
          />
        )}
        <MainContentWrapper className='layout-content-wrapper'>
          {/* AppBar Component */}
          {isMobile && <AppBar toggleNavVisibility={toggleNavVisibility} {...props} />}

          {useFullWrapper ? (
            <FullContentWrapper
              sx={{
                ...(contentWidth === 'boxed' && {
                  '@media (min-width:1200px)': { maxWidth: '100%', pt: '40px' }
                })
              }}
            >
              {children}
            </FullContentWrapper>
          ) : (
            <ContentWrapper
              className='layout-page-content'
              sx={{
                ...(contentWidth === 'boxed' && {
                  '@media (min-width:1200px)': { maxWidth: '100%', pt: '40px' }
                })
              }}
            >
              {children}
            </ContentWrapper>
          )}
          {/* Footer Component */}
          <Footer {...props} />

          {/* Portal for React Datepicker */}
          <DatePickerWrapper sx={{ zIndex: 11 }}>
            <Box id='react-datepicker-portal'></Box>
          </DatePickerWrapper>
        </MainContentWrapper>
      </VerticalLayoutWrapper>

      {/* Customizer */}
      {disableCustomizer || hidden ? null : <Customizer />}

      {/* Scroll to top button */}
      {scrollToTop ? (
        scrollToTop(props)
      ) : (
        <ScrollToTop className='mui-fixed'>
          <Fab color='primary' size='small' aria-label='scroll back to top'>
            <ArrowUp />
          </Fab>
        </ScrollToTop>
      )}
    </>
  )
}

export default VerticalLayout
