import { IAccessProfile } from 'src/types/accessProfile.type'
import { IIntegration } from 'src/types/integration.type'
import { IUser } from 'src/types/user.type'

export class User implements IUser {
  id?: number | null
  password?: string | null
  firstName: string
  lastName: string
  email: string
  accessProfileId?: number | null
  accessProfile?: IAccessProfile | null
  status?: string | null
  googleAuthenticator?: boolean | null
  googleAuthenticatorRequired?: boolean | null
  createdAt?: string | null
  updatedAt?: string | null
  integrations?: IIntegration[] | null

  constructor(user: IUser) {
    this.id = user.id || null
    this.password = user.password || null
    this.firstName = user.firstName
    this.lastName = user.lastName
    this.email = user.email
    this.accessProfileId = user.accessProfileId || null
    this.accessProfile = user.accessProfile || null
    this.status = user.status || null
    this.googleAuthenticator = user.googleAuthenticator || null
    this.googleAuthenticatorRequired = user.googleAuthenticatorRequired || null
    this.createdAt = user.createdAt || null
    this.updatedAt = user.updatedAt || null
    this.integrations = user.integrations || null
  }

  isActive(): boolean {
    return this.status === 'active'
  }

  fullName(): string {
    return `${this.firstName} ${this.lastName}`
  }

  crm(): IIntegration[] {
    return this.integrations ? this.integrations.filter(i => i.category === 'crm' && i.enabled) : []
  }

  dms(): IIntegration[] {
    return this.integrations ? this.integrations.filter(i => i.category === 'dms' && i.enabled) : []
  }

  // Check if the user has 'Vins Solutions'
  hasVins(crm: IIntegration[]): boolean {
    return crm.some(i => i.platform === 'vin_solutions' && i.enabled)
  }

  // Check if the user has 'Reynolds'
  hasReyCrm(crm: IIntegration[]): boolean {
    return crm.some(i => i.platform === 'reynolds' && i.enabled)
  }

  // Check if the user has 'DealerSocket'
  hasDs(crm: IIntegration[]): boolean {
    return crm.some(i => i.platform === 'dealer_socket' && i.enabled)
  }

  // Check if the user has 'Elead'
  hasElead(crm: IIntegration[]): boolean {
    return crm.some(i => i.platform === 'elead' && i.enabled)
  }

  // Check if the user has 'CDK'
  hasCdk(dms: IIntegration[]): boolean {
    return dms.some(i => i.platform === 'cdk' && i.enabled)
  }

  // Check if the user has 'Reynolds'
  hasReyDms(dms: IIntegration[]): boolean {
    return dms.some(i => i.platform === 'reynolds' && i.enabled)
  }

  // Check if the user has 'Open Track'
  hasOpenTrack(dms: IIntegration[]): boolean {
    return dms.some(i => i.platform === 'open_track' && i.enabled)
  }
}
