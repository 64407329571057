import axiosInstance from './serverAxios'
import {
  IGoogleAuthenticatorSecret,
  IGoogleAuthenticatorStatusResponse,
  IGoogleAuthenticatorVerificationParams
} from 'src/types/googleAuthenticator.type'
import { IServerUser } from 'src/types/user.type'

class GoogleAuthenticatorService {
  generateSecret() {
    return axiosInstance.get<IGoogleAuthenticatorSecret>('/v1/google-authenticator/generate-secret')
  }

  verify(data: IGoogleAuthenticatorVerificationParams) {
    return axiosInstance.post<IServerUser>('/v1/google-authenticator/verify', data)
  }

  status() {
    return axiosInstance.get<IGoogleAuthenticatorStatusResponse>('/v1/google-authenticator/status')
  }
}

export default new GoogleAuthenticatorService()
