// ** MUI Imports
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

const FooterContent = () => {
  return (
    <Box
      data-html2canvas-ignore='true'
      sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'space-between' }}
    >
      <Typography sx={{ mr: 2, fontSize: '12px' }}>{`© ${new Date().getFullYear()}, Dashbi LLC`}</Typography>
    </Box>
  )
}

export default FooterContent
