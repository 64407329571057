// ** Theme Type Import
import { Theme } from '@mui/material/styles'

const Typography = (theme: Theme) => {
  return {
    h1: {
      fontSize: 96,
      fontWeight: 500,
      lineHeight: 1.167,
      letterSpacing: '-1.5px',
      color: theme.palette.text.primary
    },
    h2: {
      fontSize: 60,
      fontWeight: 500,
      lineHeight: 1.2,
      letterSpacing: '-0.5px',
      color: theme.palette.text.primary
    },
    h3: {
      fontSize: 48,
      fontWeight: 500,
      lineHeight: 1.167,
      letterSpacing: 0,
      color: theme.palette.text.primary
    },
    h4: {
      fontSize: 32,
      fontWeight: 500,
      lineHeight: 1.25,
      letterSpacing: '0.25px',
      color: theme.palette.text.primary
    },
    h5: {
      fontSize: 24,
      fontWeight: 500,
      lineHeight: 1.334,
      letterSpacing: 0,
      color: theme.palette.text.primary
    },
    h6: {
      fontSize: 20,
      fontWeight: 500,
      lineHeight: 1.6,
      letterSpacing: '0.15px',
      color: theme.palette.text.primary
    },
    subtitle1: {
      fontSize: 16,
      fontWeight: 400,
      lineHeight: 1.75,
      letterSpacing: '0.15px',
      color: theme.palette.text.primary
    },
    subtitle2: {
      fontSize: 14,
      fontWeight: 500,
      lineHeight: 1.57,
      letterSpacing: '0.1px',
      color: theme.palette.text.secondary
    },
    body1: {
      fontSize: 16,
      fontWeight: 400,
      lineHeight: 1.5,
      letterSpacing: '0.15px',
      color: theme.palette.text.primary
    },
    body2: {
      fontSize: 14,
      fontWeight: 400,
      lineHeight: 1.43,
      letterSpacing: '0.15px',
      color: theme.palette.text.secondary
    },
    button: {
      letterSpacing: '0.4px',
      color: theme.palette.text.primary
    },
    caption: {
      fontSize: 12,
      fontWeight: 400,
      lineHeight: 1.25,
      letterSpacing: '0.4px',
      color: theme.palette.text.secondary
    },
    overline: {
      fontSize: 12,
      fontWeight: 400,
      lineHeight: 1.25,
      letterSpacing: '1px',
      color: theme.palette.text.secondary
    },
    toast: {
      fontSize: 16,
      fontWeight: 400,
      lineHeight: 1.3125,
      letterSpacing: '0.14px',
      color: theme.palette.text.primary
    }
  }
}

export default Typography
