// ** MUI Imports
import { Theme } from '@mui/material/styles'

const DataGrid = (theme: Theme) => {
  return {
    MuiDataGrid: {
      styleOverrides: {
        root: {
          border: 0,
          color: theme.palette.text.primary,
          '& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-columnHeader:focus-within': {
            outline: 'none'
          },
          '& .css-204u17-MuiDataGrid-main': {
            backgroundColor: `${theme.palette.background.paper} !important`,
            borderRadius: '16px 16px 0px 0px'
          },
          '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {
            height: '12px'
          },
          '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track': {
            background: `${theme.palette.background.paper} !important`
          },
          '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb': {
            backgroundColor: `${theme.palette.divider}`,
            borderRadius: '12px',
            border: '1px solid',
            borderColor: `${theme.palette.divider}`
          },
          '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover': {
            background: `${theme.palette.divider}`
          }
        },
        toolbarContainer: {
          paddingRight: `${theme.spacing(5)} !important`,
          paddingLeft: `${theme.spacing(3.25)} !important`,
          display: 'flex',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
          [theme.breakpoints.down('sm')]: {
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-start'
          }
        },
        columnHeaders: {
          maxHeight: '54px !important',
          minHeight: '54px !important',
          lineHeight: '24px !important',
          display: 'flex',
          backgroundColor: theme.palette.customColors.tableHeaderBg
        },
        columnHeader: {
          height: '54px',
          '& .MuiDataGrid-columnHeaderTitle': {
            fontSize: '14px !important',
            fontWeight: '600 !important'
          },
          color: `${theme.palette.text.primary} !important`,
          '&:not(.MuiDataGrid-columnHeaderCheckbox)': {
            padding: theme.spacing(4),
            '&:first-of-type': {
              paddingLeft: theme.spacing(5)
            }
          },
          '&:last-of-type': {
            paddingRight: theme.spacing(5)
          }
        },
        columnHeaderCheckbox: {
          maxWidth: '58px !important',
          minWidth: '58px !important'
        },
        columnHeaderTitleContainer: {
          padding: 0
        },
        columnHeaderTitle: {
          fontSize: '0.75rem',
          letterSpacing: '0.17px'
        },
        columnSeparator: {
          color: theme.palette.divider
        },
        virtualScroller: {
          marginTop: '54px !important'
        },
        virtualScrollerRenderZone: {
          '& .MuiDataGrid-row': {
            maxHeight: '50px !important',
            minHeight: '50px !important'
          }
        },
        row: {
          '&:last-child': {
            '& .MuiDataGrid-cell': {
              borderBottom: 0
            }
          }
        },
        cell: {
          maxHeight: '50px !important',
          minHeight: '50px !important',
          lineHeight: '20px !important',
          borderColor: theme.palette.divider,
          '&:not(.MuiDataGrid-cellCheckbox)': {
            padding: theme.spacing(4),
            '&:first-of-type': {
              paddingLeft: theme.spacing(5)
            }
          },
          '&:last-of-type': {
            paddingRight: theme.spacing(5)
          },
          '&:focus, &:focus-within': {
            outline: 'none'
          }
        },
        cellCheckbox: {
          maxWidth: '58px !important',
          minWidth: '58px !important'
        },
        editInputCell: {
          padding: 0,
          color: theme.palette.text.primary,
          '& .MuiInputBase-input': {
            padding: 0
          }
        },
        footerContainer: {
          minHeight: '50px !important',
          borderTop: `1px solid ${theme.palette.divider}`,
          backgroundColor: `${theme.palette.background.paper} !important`,
          '& .MuiTablePagination-toolbar': {
            minHeight: '50px !important'
          },
          '& .MuiTablePagination-select': {
            color: theme.palette.text.primary
          }
        }
      },
      defaultProps: {
        rowHeight: 50,
        headerHeight: 54
      }
    }
  }
}

export default DataGrid
