// ** React Imports
import { ReactNode, ReactElement, useEffect } from 'react'

// ** Next Imports
import { useRouter } from 'next/router'

// ** Hooks Import
import { useAuth } from 'src/hooks/useAuth'
import toast from 'react-hot-toast'

interface AuthGuardProps {
  children: ReactNode
  fallback: ReactElement | null
}

const AuthGuard = (props: AuthGuardProps) => {
  const { children, fallback } = props
  const auth = useAuth()
  const router = useRouter()

  useEffect(
    () => {
      if (auth.user === null && !window.localStorage.getItem('userData')) {
        if (router.asPath !== '/') {
          router.replace({
            pathname: '/login',
            query: { returnUrl: router.asPath }
          })
        } else {
          router.replace('/login')
        }
      }

      if (
        auth.user !== null &&
        auth.user.googleAuthenticatorRequired === true &&
        router.route !== '/login/google-authenticator' &&
        router.route !== '/profile' &&
        router.route !== '/'
      ) {
        if (auth.user.googleAuthenticator === false) {
          toast.error(
            'Google Authenticator is required for two-factor authentication to ensure the utmost security while using our app. Please set it up to proceed.'
          )
          router.replace('/profile/')
        } else {
          if (router.asPath !== '/') {
            router.replace({
              pathname: '/login/google-authenticator/',
              query: { returnUrl: router.asPath }
            })
          } else {
            router.replace('/login/google-authenticator/')
          }
        }
      }

      if (!router.isReady) {
        return
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [router.route, auth.user]
  )

  if (auth.loading || auth.user === null) {
    return fallback
  }

  return <>{children}</>
}

export default AuthGuard
