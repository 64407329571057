import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { camelizeKeys, decamelizeKeys } from 'humps'

const axiosInstance = axios.create({
  baseURL: process.env.apiBaseUrl,
  withCredentials: true,
  timeout: 60000,
  headers: {
    'X-Application-Secret': `${process.env.apiSecret}`
  }
})

// Axios middleware to convert all api responses to camelCase
axiosInstance.interceptors.response.use((response: AxiosResponse) => {
  if (response.data && response.headers['content-type'].includes('application/json')) {
    response.data = camelizeKeys(response.data)
  }

  return response
})

// Axios middleware to convert all api requests to snake_case
axiosInstance.interceptors.request.use((config: AxiosRequestConfig) => {
  const newConfig = { ...config }
  newConfig.url = `${config.url}`
  if (newConfig.headers != null && newConfig.headers['Content-Type'] === 'multipart/form-data') {
    return newConfig
  }
  if (config.params) {
    newConfig.params = decamelizeKeys(config.params)
  }
  if (config.data) {
    newConfig.data = decamelizeKeys(config.data)
  }

  return newConfig
})

export default axiosInstance
