// ** MUI Imports
import Box from '@mui/material/Box'

// ** Components
import UserDropdown from 'src/layouts/components/UserDropdown'

const AppBarContent = () => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <UserDropdown />
    </Box>
  )
}

export default AppBarContent
