// ** Type Imports
import { PaletteMode } from '@mui/material'
import { Skin } from 'src/@core/layouts/types'

const DefaultPalette = (mode: PaletteMode, skin: Skin) => {
  // ** Vars
  const lightColor = '76, 78, 100'
  const darkColor = '234, 234, 255'
  const mainColor = mode === 'light' ? lightColor : darkColor

  const defaultBgColor = () => {
    if (skin === 'bordered' && mode === 'light') {
      return '#FFF'
    } else if (skin === 'bordered' && mode === 'dark') {
      return '#182335'
    } else if (mode === 'light') {
      return '#F3F6FE'
    } else return '#111926'
  }

  return {
    customColors: {
      dark: darkColor,
      main: mainColor,
      light: lightColor,
      darkBg: '#282A42',
      lightBg: '#F7F7F9',
      bodyBg: mode === 'light' ? '#F7F7F9' : '#282A42', // Same as palette.background.default but doesn't consider bordered skin
      tooltipBg: mode === 'light' ? '#262732E6' : '#4C699EE6',
      tableHeaderBg: mode === 'light' ? '#E2E9FC' : '#2A4065',
      primaryBg: mode === 'light' ? '#D1ECC0' : '#244F56',
      secondaryBg: mode === 'light' ? '#DDE4FF' : '#17325A',
      errorBg: mode === 'light' ? '#FFEAE9' : '#462B39',
      warningBg: mode === 'light' ? '#FFF6E5' : '#3A402E',
      successBg: mode === 'light' ? '#E5FFD4' : '#33D46B',
      infoBg: mode === 'light' ? '#E5F8FE' : '#193F5D',
      backdropOverlay: mode === 'light' ? '#4C4E6480' : '#34486C80',
      inputBorder: mode === 'light' ? '#E4EAFF' : '#34486C',
      filledInputBg: mode === 'light' ? '#4C4E640A' : '#34486C0A',
      chipBg: mode === 'light' ? '#F2F4F7' : '#8D92C814'
    },
    common: {
      black: '#000',
      white: '#FFF'
    },
    mode: mode,
    primary: {
      light: mode === 'light' ? '#EEFBE5' : '#DDFFF8',
      main: mode === 'light' ? '#4CB109' : '#37E574',
      dark: mode === 'light' ? '#3F8A0D' : '#30C765',
      contrastText: mode === 'light' ? '#FFF' : '#010202'
    },
    secondary: {
      light: mode === 'light' ? '#EEF2FF' : '#EEF2FF',
      main: mode === 'light' ? '#5574E3' : '#3776CC',
      dark: mode === 'light' ? '#314DB0' : '#346FBF',
      contrastText: '#FFF'
    },
    success: {
      light: mode === 'light' ? '#83E542' : '#DDFFF8',
      main: mode === 'light' ? '#72E128' : '#3BCC6E',
      dark: mode === 'light' ? '#64C623' : '#3FD975',
      contrastText: mode === 'light' ? '#FFF' : '#010202'
    },
    error: {
      light: mode === 'light' ? '#FF625F' : '#FFE6E5',
      main: mode === 'light' ? '#FF4D49' : '#C24C49',
      dark: mode === 'light' ? '#E04440' : '#B54744',
      contrastText: '#FFF'
    },
    warning: {
      light: mode === 'light' ? '#FDBE42' : '#F7F3CF',
      main: mode === 'light' ? '#FDB528' : '#CCAE5A',
      dark: mode === 'light' ? '#DF9F23' : '#BFA354',
      contrastText: mode === 'light' ? '#FFF' : '#8D92C814'
    },
    info: {
      light: mode === 'light' ? '#40CDFA' : '#EBFAFF',
      main: mode === 'light' ? '#26C6F9' : '#58BDDD',
      dark: mode === 'light' ? '#21AEDB' : '#53B3D1',
      contrastText: '#FFF'
    },
    grey: {
      50: '#FAFAFA',
      100: '#F5F5F5',
      200: '#EEEEEE',
      300: '#E0E0E0',
      400: '#BDBDBD',
      500: '#9E9E9E',
      600: '#757575',
      700: '#616161',
      800: '#424242',
      900: mode === 'light' ? '#212121' : '#4E618F',
      A100: '#D5D5D5',
      A200: '#AAAAAA',
      A400: '#616161',
      A700: '#303030'
    },
    text: {
      primary: mode === 'light' ? 'rgba(12, 22, 58, 1)' : 'rgba(255, 255, 255, 1)',
      secondary: mode === 'light' ? 'rgba(97, 111, 136, 1)' : 'rgba(173, 183, 220, 1)',
      disabled: mode === 'light' ? `rgba(${mainColor}, 0.38)` : 'rgba(255, 255, 255, 0.38)'
    },
    divider: mode === 'light' ? 'rgba(229, 234, 251, 1)' : 'rgba(42, 56, 83, 1)',
    background: {
      paper: mode === 'light' ? '#FFF' : '#182335',
      default: defaultBgColor()
    },
    action: {
      active: mode === 'light' ? `rgba(208, 211, 245, 0.54)` : 'rgba(59, 62, 78, 0.54)',
      hover: mode === 'light' ? `rgba(208, 211, 245, 0.48)` : 'rgba(43, 55, 84, 1)',
      hoverOpacity: 0.05,
      selected: mode === 'light' ? `rgba(208, 211, 245, 0.52)` : 'rgba(159, 167, 255, 0.08)',
      disabled: mode === 'light' ? `rgba(208, 211, 245, 0.26)` : 'rgba(159, 167, 255, 0.26)',
      disabledBackground: mode === 'light' ? `rgba(208, 211, 245, 0.12)` : 'rgba(159, 167, 255, 0.12)',
      focus: mode === 'light' ? `rgba(208, 211, 245, 0.12)` : 'rgba(159, 167, 255, 0.12)'
    },
    states: {
      otlBorderPrimary: mode === 'light' ? 'rgba(76, 177, 9, 0.5)' : 'rgba(55, 167, 116, 0.5)',
      otlBorderSecondary: mode === 'light' ? 'rgba(85, 116, 227, 0.5)' : 'rgba(22, 117, 247, 0.5)',
      otlBorderError: mode === 'light' ? 'rgba(255, 77, 73, 0.5)' : 'rgba(219, 48, 44, 0.5)',
      otlBorderWarning: mode === 'light' ? 'rgba(253, 181, 40, 0.5)' : 'rgba(213, 194, 17, 0.5)',
      otlBorderSuccess: mode === 'light' ? 'rgba(114, 225, 40, 0.5)' : 'rgba(51, 212, 107, 0.5)',
      otlBorderInfo: mode === 'light' ? 'rgba(38, 198, 249, 0.5)' : 'rgba(29, 174, 255, 0.5)',
      otlHoverBgPrimary: mode === 'light' ? 'rgba(76, 177, 9, 0.05)' : 'rgba(55, 229, 116, 0.05)',
      otlHoverBgSecondary: mode === 'light' ? 'rgba(109, 120, 141, 0.05)' : 'rgba(52, 72, 108, 0.05)',
      otlHoverBgError: mode === 'light' ? 'rgba(255, 77, 73, 0.05)' : 'rgba(205, 39, 35, 0.05)',
      otlHoverBgWarning: mode === 'light' ? 'rgba(253, 181, 40, 0.05)' : 'rgba(213, 194, 17, 0.05)',
      otlHoverBgSuccess: mode === 'light' ? 'rgba(114, 225, 40, 0.05)' : 'rgba(51, 212, 107, 0.05)',
      otlHoverBgInfo: mode === 'light' ? 'rgba(38, 198, 249, 0.05)' : 'rgba(29, 174, 255, 0.05)',
      otlSelectedBg: mode === 'light' ? 'rgba(76, 177, 9, 0.08)' : 'rgba(55, 229, 116, 0.08)',
      contHoverBgPrimary: mode === 'light' ? 'rgba(63, 138, 13, 1)' : 'rgba(93, 234, 142, 1)',
      contHoverBgSecondary: mode === 'light' ? 'rgba(49, 77, 176, 1)' : 'rgba(63, 141, 248, 1)',
      contHoverBgError: mode === 'light' ? 'rgba(221, 17, 51, 1)' : 'rgba(225, 84, 81, 1)',
      contHoverBgWarning: mode === 'light' ? 'rgba(207, 148, 33, 1)' : 'rgba(237, 217, 33, 1)',
      contHoverBgSuccess: mode === 'light' ? 'rgba(93, 185, 33, 1)' : 'rgba(87, 219, 133, 1)',
      contHoverBgInfo: mode === 'light' ? 'rgba(31, 162, 204, 1)' : 'rgba(112, 204, 255, 1)',
      activeMenu: mode === 'light' ? 'rgba(76, 177, 9, 1)' : 'rgba(55, 229, 116, 1)'
    },
    alert: {
      errorContent: mode === 'light' ? 'rgba(229, 69, 66, 1)' : 'rgba(219, 48, 44, 1)',
      warningContent: mode === 'light' ? 'rgba(228, 163, 36, 1)' : 'rgba(213, 194, 17, 1)',
      infoContent: mode === 'light' ? 'rgba(34, 178, 224, 1)' : 'rgba(29, 174, 255, 1)',
      successContent: mode === 'light' ? 'rgba(103, 202, 36, 1)' : 'rgba(48, 199, 101, 1)'
    }
  }
}

export default DefaultPalette
