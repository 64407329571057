import { AbilityBuilder, Ability } from '@casl/ability'
import { IAccessProfile } from 'src/types/accessProfile.type'

export type Subjects = string
export type Actions = 'manage' | 'create' | 'read' | 'update' | 'delete'

export type AppAbility = Ability<[Actions, Subjects]> | undefined

export const AppAbility = Ability as any
export type ACLObj = {
  action: Actions
  subject: string
}

/**
 * Please define your own Ability rules according to your app requirements.
 * We have just shown Admin and Client rules for demo purpose where
 * admin can manage everything and client can just visit ACL page
 */
const defineRulesFor = (accessProfile: IAccessProfile) => {
  const { can, rules } = new AbilityBuilder(AppAbility)

  Object.entries(accessProfile.permissions as { [key: string]: unknown }).map(([innerKey, innerValue]) => {
    const permissions: string[] = []
    Object.entries(innerValue as { [key: string]: unknown }).map(([key, value]) => {
      if (value == '1') permissions.push(key)
    })
    if (permissions.length > 0) can(permissions, innerKey)
  })

  can('read', 'home')
  can('read', 'googleAuthenticator')
  can('manage', 'profile')

  return rules
}

export const buildAbilityFor = (accessProfile: IAccessProfile): AppAbility => {
  return new AppAbility(defineRulesFor(accessProfile), {
    // https://casl.js.org/v5/en/guide/subject-type-detection
    // @ts-ignore
    detectSubjectType: object => object!.type
  })
}

export const defaultACLObj: ACLObj = {
  action: 'manage',
  subject: 'all'
}

export default defineRulesFor
